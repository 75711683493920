import 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {
  getFirestore,
  initializeFirestore,
  connectFirestoreEmulator,
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDFW_BEVrlJl6INEqZKcAwdf-G_S_uFKpk',
  authDomain: 'db-test-d5844.firebaseapp.com',
  projectId: 'db-test-d5844',
  storageBucket: 'db-test-d5844.appspot.com',
  messagingSenderId: '891890411998',
  appId: '1:891890411998:web:83ea7b219d8f5403a2154f',
  measurementId: 'G-8WTWLW4LYE',
};

export const app = firebase.initializeApp(firebaseConfig);

export const db = getFirestore(app);

const auth = firebase.auth();

export default auth;
