import utils from '../../utils';
const Head = () => {
  return (
    <>
      {utils.generateMetatags({
        url: '/',
        title: 'Blog Codeeweb | Home',
        description:
          'Falamos sobre evolução ou criação do seu site ou produto digital, temos o foco de avaliar a arquitetura, escalabilidade e manutenção de código',
        color: '#2c4bff',
      })}
    </>
  );
};

export default Head;
