const {
  collection,
  query,
  where,
  addDoc,
  getDocs,
} = require('firebase/firestore');
const { db } = require('../services/auth');

const getAll = async () => {
  const projectsRef = collection(db, 'posts');

  const snap = await getDocs(projectsRef);
  let projects = [];
  snap.forEach((doc) => {
    projects.push({ id: doc.id, ...doc.data() });
  });
  return projects;
};

export default {
  getAll,
};
