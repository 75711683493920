import Header from 'components/Header';
import Footer from 'components/Footer';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import services from '../../services/';
import { graphql } from 'gatsby';
import Head from './Head';
import useBlog from './useBlog';
import ReactPaginate from 'react-paginate';

const Items = ({ currentItems, props }) => {
  return (
    <>
      {currentItems &&
        currentItems.map((el) => {
          const img = props.data.photos.edges.find(
            (img) => img.node.base == el.imageName
          );

          console.log('props.data.photos', props);
          console.log('img', img);

          const imageUrl = getImage(img.node);
          return (
            <article className='col-12 col-sm-6 col-lg-4' key={el.id}>
              <div className='d-flex h-100 bg-white rounded card overflow-hidden shadow-lg position-relative hover-lift'>
                <picture>
                  <GatsbyImage
                    className='img-fluid'
                    image={imageUrl}
                    alt={el.title}
                  />
                </picture>

                <div className='card-body p-4 p-lg-5'>
                  <p className='card-title fw-medium mb-4'>{el.title}</p>
                  <p className='card-title fw-medium mb-4 '>{el.description}</p>
                  <a
                    href={`/blog/${el.slug}`}
                    className='fw-medium fs-7 text-decoration-none link-cover'
                  >
                    Leia mais →
                  </a>
                </div>
              </div>
            </article>
          );
        })}
    </>
  );
};

const Blog = (props) => {
  const { handlePageClick, currentItems, pageCount } = useBlog({ services });

  return (
    <>
      <Head />
      <Header />
      <div className='bg-primary py-10'>
        <div className='container'>
          <h4 className='fs-1 fw-bold mb-6 text-white text-center'>
            Postagens
          </h4>
          <div className='row g-5'>
            <Items currentItems={currentItems} props={props} />
          </div>
        </div>

        <ReactPaginate
          className='pagination'
          breakLabel='...'
          nextLabel='avançar →'
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel='← voltar'
          renderOnZeroPageCount={null}
        />
      </div>
      <Footer />
    </>
  );
};

export const pageQuery = graphql`
  query {
    photos: allFile(
      sort: { fields: base, order: ASC }
      filter: { extension: { regex: "/(jpg)/" } }
    ) {
      edges {
        node {
          id
          base
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              height: 400
              formats: AUTO
              width: 600
            )
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default Blog;
